/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Popup from 'vux/src/components/popup';
import TransferDom from 'vux/src/directives/transfer-dom';
import { Dialog, Toast } from 'vant'; // ---组织结构树-----

import OrgTree from '@/components/OrgTree/OrgTree';
export default {
  components: {
    Popup: Popup,
    OrgTree: OrgTree
  },
  directives: {
    TransferDom: TransferDom
  },
  data: function data() {
    return {
      wayName: ['', '会签', '或签'],
      userLists: [],
      //展示的审批人
      //  itemUserList:[],//全部的审批人
      moreflag: false,
      //是否展示更多按钮
      showMoreBtn: false,
      addFlag: false,
      //加号的显示
      allLists: [],
      //可添加的人
      moreLists: [],
      showAllBtn: false,
      //
      msgNum: '',
      //几个人
      referAll: {},
      //提交给父级的所有人--对象
      //  -----组织结构树-----
      // approver: [],
      depSerial: this.$utils.Store.getItem('orgId'),
      //企业ID
      depName: this.$utils.Store.getItem('orgName'),
      //企业名称
      // approverString: '', //审批人序号--字符串
      choose: this.$utils.Store.getItem('choose') ? this.$utils.Store.getItem('choose') : 0,
      //添加人员类型   1：审批人  2：抄送人  3：同行人
      showFlag: false,
      //是否显示组织架构弹窗
      selectType: 0,
      //组织架构选择类型
      infoChecked: [],
      //
      approverChecked: [] //审批人选中tItem('oldVal') ? JSON.parse(this.$utils.Store.getItem('oldVal')) : [],    //审批人

    };
  },
  props: {
    itemUser: Object
  },
  watch: {
    msgNum: {
      handler: function handler(newVal) {
        this.$emit('gechaosongrenshu', newVal);

        if (newVal < 10) {
          this.addFlag = true;
        } else if (newVal >= 10) {
          this.addFlag = false;
        }
      },
      deep: true
    },
    itemUser: {
      //深度监听父组件的list
      handler: function handler(newVal) {
        var _this = this;

        this.addFlag = true;

        if (newVal.cc && newVal.copyType == 2) {
          //有固定审批 copyType==2 职务4 ||5
          newVal.cc.forEach(function (element) {
            element.copyType = newVal.copyType; //不可删除
          });
          this.moreLists = _toConsumableArray(newVal.cc);

          if (this.moreLists.length > 2) {
            this.moreflag = true;
            this.$set(this.userLists, 0, this.moreLists[0]);
          } else if (this.moreLists.length <= 2) {
            this.moreflag = false;
            this.moreLists.some(function (item, i) {
              _this.$set(_this.userLists, i, _this.moreLists[i]);
            });
          }
        }

        this.countUser();
      },
      deep: true,
      immediate: true
    },
    moreLists: {
      //添加的列表
      handler: function handler(newVal) {
        var _this2 = this;

        //
        if (newVal.length <= 2) {
          this.moreflag = false;
          this.showMoreBtn = false;
          newVal.some(function (item, i) {
            _this2.$set(_this2.userLists, i, newVal[i]);
          });
        } else if (newVal.length == 3 && this.userLists.length == 2) {
          this.moreflag = true;
          this.userLists.splice(1, 1);
        }

        this.countUser();
      },
      deep: true
    }
  },
  methods: {
    // ---删除人员的点击事件--更多列表的 lwt  07-02
    moreClearClick: function moreClearClick(i) {
      this.moreLists.splice(i, 1);
    },
    // ---删除人员的点击事件--展示列表的 lwt  07-02
    // 修改  07-17
    clearUserClick: function clearUserClick(i) {
      var _this3 = this;

      var clearUser = this.userLists.splice(i, 1);
      this.moreLists.some(function (sub, ix) {
        if (clearUser[0].userSerial == sub.userSerial) {
          _this3.moreLists.splice(ix, 1);

          return true;
        }
      }); //  this.allLists.push(clearUser[0]);

      if (this.userLists.length == 0 && this.moreLists.length > 1) {
        this.$set(this.userLists, 0, this.moreLists[0]);
      }
    },
    // 删减人员的增减的效果---统一的  lwt  07-03
    countUser: function countUser() {
      this.msgNum = this.moreLists.length;

      var lists = _toConsumableArray(this.moreLists);

      var ulists = [];
      lists.forEach(function (item) {
        ulists.push(item.userSerial);
      });
      this.referAll = {
        level: 1,
        way: this.itemUser.copyWay,
        userSerial: ulists,
        cc: '1' //我自己要加的

      };
      this.$emit('getSubUserObj', this.referAll);
    },
    // 查看名字---lwt 07-03
    moreName: function moreName(name) {
      Dialog({
        message: name,
        width: '70%',
        closeOnPopstate: true
      });
    },
    // -------以下是组织结构树的内容
    selectUsers: function selectUsers(val) {
      var _this$moreLists;

      var useflag = this.moreLists.some(function (item) {
        return item.userSerial == val[0].userSerial;
      });

      if (useflag) {
        Toast('抄送人重复');
        return;
      }

      (_this$moreLists = this.moreLists).push.apply(_this$moreLists, _toConsumableArray(val));
    },
    chooseApprover: function chooseApprover() {
      this.infoChecked = [];
      this.selectType = 1;
      this.showFlag = true;
    }
  }
};